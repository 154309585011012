<template>
  <div class="main page">
    <div class="logo-wrap">
      <img src="@/assets/img/astellas_logo.svg" alt="" />
    </div>

    <!-- 승인 전 -->
    <div v-if="isHcp === 'N'">
      <h3 class="page-header">HCP 인증이 필요한 서비스</h3>
      <div class="wrapper">
        <h1>귀하께서는 한국의 보건의료전문가입니까?</h1>
        <p>
          본 웹사이트는 보건의료전문가용으로, 아스텔라스 카카오채널 내 보건의료전문가 식별과정을 위해 개설
          되었습니다.<br />
          회원가입 신청 또는 회원정보 변경 시 허위내용을 기재하거나 타인의 명의를 이용할 경우, 다른 회원의 비밀번호와
          정보를 도용할 경우 해당 정보는 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
        </p>
      </div>
    </div>

    <!-- 승인 완료 -->
    <div v-if="isHcp === 'Y'">
      <h3 class="page-header">HCP 인증 완료</h3>
      <div class="wrapper">
        <h1>HCP인증이 완료 되었습니다.</h1>
        <p>채팅방 내 퀵메뉴로 다양한 정보를 확인해보세요.</p>
      </div>
    </div>

    <!-- 승인 대기 -->
    <div v-if="isHcp === 'W'">
      <h3 class="page-header">HCP 대기 중</h3>
      <div class="wrapper">
        <h1>HCP 승인 대기 중입니다.</h1>
        <p>
          HCP 인증 대기중으로 시간이 소요될 수 있습니다. 빠른 시일 내에 처리 후 승인 완료 시 채널 알림톡을 통해 안내 드리겠습니다.
        </p>
      </div>
    </div>

    <div class="bottom-button">
      <div v-if="['N'].includes(isHcp)">
        <a-button type="primary" size="large" block class="btn-close" @click="clickRegisterBtn">
          네, 맞습니다 (HCP 인증하기)
        </a-button>
        <a-button size="large" block class="btn-close mar-to10" @click="clickCloseBtn">
          아니오 (카카오톡 채널로 돌아가기)
        </a-button>
      </div>
      <!-- <div v-else>
        <a-button size="large" block class="btn-close mar-to10" @click="clickGoProductBtn"> 제품정보 보기 </a-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      isDev: process.env.NODE_ENV === 'development',
      isHcp: 'N',
      isUser: null,
    };
  },
  mounted() {
    // 접근 아이디가 hcp승인자인지 확인
    // console.log('', this.$route.query.kakaoId);
    if (this.$route.query.kakaoId === undefined) {
      this.isHcp = 'N';
      return;
    }
    this.saveKakaoInfo(this.$route.query);
    this.$route.query.queryPath = 'home';
    this.getHcpInfo(this.$route.query).then((res) => {
      // console.log('', res.isHcp);
      if (res === null) {
        this.isHcp = 'N';
        return;
      }
      this.isHcp = res.isHcp;
    });
  },
  methods: {
    clickCloseBtn() {
      location.href = 'kakaotalk://inappbrowser/close';
    },
    clickRegisterBtn() {
      // console.log(this.me);
      // if (this.me) {
      //   Vue.$toast.open({
      //     message: '회원 정보 삭제 완료 후 회원가입이 가능합니다.',
      //     type: 'default',
      //     duration: 1000,
      //     dismissible: true,
      //   });
      //   return;
      // }
      this.$router.push('/signup');
    },
    clickGoProductBtn() {
      this.$router.push('/product');
    },
    ...mapActions(['saveKakaoInfo', 'getHcpInfo']),
  },
  computed: {
    ...mapState(['me']),
  },
};
</script>

<style lang="scss">
.main {
  .page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
  }
  .wrapper {
    margin-top: 45px;
    padding: 40px 0;
    min-height: calc(100vh - 253px);
    hr {
      margin: 20px 0;
    }
    h1 {
      margin: 0 0 20px;
      font-size: 18px;
      font-weight: 900;
      color: #000000;
      text-align: left;
    }
    h2 {
      color: $secondary-color;
      margin: 0;
      font-size: 16px;
    }
    p {
      color: #000000;
      // font-weight: bold;
      margin: 0 0 20px;
      font-size: 16px;
      word-break: keep-all;
    }
    span {
      display: inline-block;
      color: #707070;
      // margin: 0 0 20px;
    }
  }
}

.bottom-button {
  padding: 20px 0;
  .btn-close {
    width: 100%;
  }
}
</style>